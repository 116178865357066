import React, { useEffect, useState } from 'react';
import { sendPost } from "../utils/helper";
import "../styles/checkLinkForm.css"

interface CheckLinkFormProps {
  onResultReceived: (
    proxies: any[],
    checkUrls: any[],
    isShow: boolean,
  ) => void;
  alias_name?: string | null;
  prefix_urls?: string;
}

const CheckLinkForm: React.FC<CheckLinkFormProps> = ({ onResultReceived, alias_name = null, prefix_urls = ""}) => {
  const [urlInput, setUrlInput] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setUrlInput(prefix_urls)
  }, [prefix_urls, setUrlInput])

  const handleCheckLink = async () => {
    onResultReceived([], [], false);
    if (!urlInput.trim()) {
      alert('Please input minimum 1 URL or Domain!');
      return;
    }

    setIsDisabled(true);
    const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/;
    const urls = urlInput.split('\n').map(line => {
      const trimmedLine = line.trim();
      if (trimmedLine && !/^https?:\/\//.test(trimmedLine)) {
        return `https://${trimmedLine}`;
      }
      return trimmedLine;
    }).filter(line => line);

    for (let i = 0; i < urls.length; i++) {
        const line = urls[i];
        if (line && !regex.test(line)) {
          alert(`URL Not in valid: "${line}"`);
          return;
        }
    }

    const bodyPost: {[key: string]: any} = {urls}
    if (alias_name) {
      bodyPost.alias_name = alias_name;
    }
    try {
      const response = await sendPost("api/admin/check_link/url_check_link",
        bodyPost, {
        headers: {
          Accept: "application/json",
        }
      })
      if (response.data) {
        onResultReceived(response.data.proxies, response.data.check_urls, true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <div className="body-content">
      <h2>Check URL or Domain</h2>
      <textarea
        id="url-input"
        className="textarea-url"
        value={urlInput || ""}
        onChange={e => setUrlInput(e.target.value)}
        placeholder="Input your url, domain. You can input multiple domains on many lines"
      />
      <button
        id="check-link-btn"
        className="btn-check"
        onClick={handleCheckLink}
        disabled={isDisabled}
      >
        Check Link
      </button>
    </div>
  );
};

export default CheckLinkForm;
