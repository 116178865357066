import React, { useState, useRef } from "react";
import {setCookie, checkLogin, sendPost} from "../utils/helper"
import { useNavigate, useLocation } from "react-router-dom";
import "../styles/login.css";

const FromLogin: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();
  const hasCheckedLogin = useRef(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await sendPost("api/login", {
        username,
        password,
      }, {
        headers: {
          Accept: "application/json"
        }
      });
      const data = response.data;
      setCookie("access_token", data.access_token, 1);
      setCookie("token_type", data.token_type, 1);
      navigate("/admin");
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        setErrorMessage("Invalid username or password");
      } else {
        setErrorMessage("An error occurred. Please try again.");
      }
      console.error("Login error:", error);
    }
  };

  React.useEffect(() => {
    if (!hasCheckedLogin.current) {
      checkLogin(navigate, location);
      hasCheckedLogin.current = true;
    }
  }, [hasCheckedLogin, navigate, location]);

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit}>
        <h2>Login</h2>
        <div className="input-group">
          <label htmlFor="username">User Name:</label>
          <input
            type="text"
            id="username"
            name="username"
            required
            placeholder="Enter your username ..."
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            required
            placeholder="Enter your password ..."
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit">Login</button>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </form>
    </div>
  );
};

export default FromLogin;
