import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sendPost } from "../utils/helper";
import "../styles/prefixPage.css"
import {
    Tabs,
    CheckLinkForm,
    ResultTable,
} from "../components";

const PrefixPage: React.FC = () => {
    const navigate = useNavigate();
    const { alias_name } = useParams<{ alias_name: string }>();
    const [proxies, setProxies] = useState<any[]>([]);
    const [checkUrls, setCheckUrls] = useState<any[]>([]);
    const [is_show, setIsShow] = useState<boolean>(false);
    const [urls, setUrls] = useState<string>("");

    useEffect(() => {
        sendPost(`api/group-prefix/get/${alias_name}`, {}, {
            headers: {
                Accept: "application/json",
            }
        }).then(res => {
            if (!res.data.prefix) {
                navigate("/admin/check-link")
                return;
            }
            setUrls(res.data.prefix.urls)
        })
        .catch(error => console.log(error));
    }, [alias_name, navigate])

    const handleResultReceived = (newProxies: any[], newCheckUrls: any[], is_show: boolean) => {
        setProxies(newProxies);
        setCheckUrls(newCheckUrls);
        setIsShow(is_show);
    }

  return (
    <div className="container">
        <Tabs />
        <CheckLinkForm onResultReceived={handleResultReceived} alias_name={alias_name} prefix_urls={urls}/>
        <ResultTable proxies={proxies} checkUrls={checkUrls} isShow={is_show}/>
    </div>
  );
};

export default PrefixPage;
