import React from "react";
import ProxyList from "../components/ProxyList";
import Tabs from "../components/Tabs";
import "../styles/proxies.css"

const AdminPage: React.FC = () => {
  return (
    <div className="container">
      <Tabs/>
      <ProxyList/>
    </div>
  );
};

export default AdminPage;
