import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Dropdown from "./Dropdown"
import { getCookie, sendPost } from "../utils/helper";
import "../styles/tabs.css"
import { Prefix } from "../Interfaces/InterfacePrefix";

const Tabs: React.FC = () => {
  const navigate = useNavigate();
  const [options, setOptions] = useState<Prefix[]>([]);

  const handleAddOption = async (newOption: string) => {
    const res = await sendPost("api/group-prefix/add", {
      "prefix_name": newOption,
    }, {
      headers: {
        Accept: "application/json",
      }
    });
    if (!res.data.prefix) {
      return;
    }
    setOptions([...options, res.data.prefix])
  };

  const onDeleteOption = (removeOption: string = "") => {
    setOptions(options.filter(option => option.alias_name !== removeOption));
    navigate("/admin/check-link");
  }

  useEffect( () => {
    sendPost("api/users/me", {}, {
      headers: {
        Authorization: `${getCookie("token_type")} ${getCookie("access_token")}`
      }
    }).catch(error => {
      console.log(error);
      navigate("/admin/login");
    })

    sendPost("api/group-prefix", {}, {
      headers: {
        Accept: "application/json",
      }
    }).then(res => setOptions(res.data.prefixes))
    .catch(error => console.log(error));
  }, [navigate])

  return (
    <div className="tab-bar">
      <button className="tab"
        onClick={() => navigate("/admin")}
      >
        List Proxies
      </button>
      <button className="tab"
        onClick={() => navigate("/admin/check-link")}
      >
        Check Block Link
      </button>
      <Dropdown options={options} onAddOption={handleAddOption} onDeleteOption={onDeleteOption}/>
    </div>
  );
};

export default Tabs;
