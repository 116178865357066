// components/ProxyList.tsx
import React, { ChangeEvent, useEffect, useState } from "react";
import { sendPost } from "../utils/helper"
import ProxyRow from "./ProxyRow";
import { Proxy, } from "../Interfaces"
import "../styles/proxyList.css";

const ProxyList: React.FC = () => {
    const [proxies, setProxies] = useState<Proxy[]>([]);
    const [isCheckedAll, setIsCheckedAll] = useState(false);
    const [checkedItems, setCheckedItems] = useState<number[]>([]);

    const regex = /^(http|https|socks5|socks4):\/\/([A-Za-z0-9.-]+):(\d{1,5}):([A-Za-z0-9._%+-]+):([A-Za-z0-9._%+-]+)$/;

    const testProxy = (proxy: string = "") => {
        return regex.test(proxy);
    };

    useEffect(() => {
        getListProxies();
    }, []);

    const getListProxies = async () => {
        try {
            const response = await sendPost("api/admin/proxies");
            setProxies(response.data.proxies || []);
        } catch (error) {
            console.error("Error fetching proxies", error);
        }
    };

    const addProxiesToDB = async (proxyUrl: string) => {
        const res = await sendPost("api/admin/proxies/add", {
            "proxy_url": proxyUrl
        }, {
            headers: {
                "Content-Type": "application/json"
            },
        })
        if (!res.data.is_add) {
            alert("Add Proxies has wrong !");
            return;
        }
        window.location.reload();
    }

    const editProxiesToDB = async (id: number, proxyUrl: string) => {
        const res = await sendPost("api/admin/proxies/edit", {
            "proxy_url": proxyUrl,
            "id_proxy": id
        }, {
            headers: {
                "Content-Type": "application/json"
            },
        })
        if (!res.data.is_edit) {
            alert("Edit Proxy has wrong !");
            return;
        }
        window.location.reload();
    }

    const btnHandleAddProxy = (e: React.MouseEvent<HTMLButtonElement>) => {
        let proxyUrl = prompt("Input your proxy address:");
        if (proxyUrl === null) {
            return;
        }

        if (!testProxy(proxyUrl)) {
            alert("Input your Proxy Wrong!! \nEX: http|https|socks4|socks5://ip:port:username:password");
            return;
        }
        addProxiesToDB(proxyUrl);
    }

    const deleteProxy = async (id: number) => {
        const res = await sendPost("api/admin/proxies/delete", {
            "id_proxy": id
        })
        if (!res.data.is_delete) {
            alert("Delete proxy has wrong !");
            return;
        }
        setProxies((prevProxies) => prevProxies.filter(proxy => proxy.id !== id));
        setCheckedItems((prevCheckedItems) => prevCheckedItems.filter(itemId => itemId !== id));
    }

    const editProxy = async (id: number, proxy: string) => {
        let proxyUrl = prompt(`Do you want to edit your proxy address: ${proxy}`, proxy);
        if (proxyUrl === null) {
            return;
        }

        if (!testProxy(proxyUrl)) {
            alert("Input your Proxy Wrong!! \nEX: http|https|socks4|socks5://ip:port:username:password");
            return;
        }
        editProxiesToDB(id, proxyUrl);
    }

    return (
        <div>
            <h3>List Proxies</h3>
            <div className="proxy-actions">
                <button onClick={btnHandleAddProxy}>Add Proxy</button>
            </div>
            <table className="table-proxies">
                <thead>
                    <tr className="tr_head">
                        <th>
                            <label>
                                <input type="checkbox" style={{ display: "inline-block" }}
                                checked={isCheckedAll}
                                onChange={ (e: ChangeEvent<HTMLInputElement>) => {
                                    const isChecked = e.target.checked;
                                    setIsCheckedAll(isChecked);
                                    if (isChecked) {
                                        setCheckedItems(proxies.map(proxy => proxy.id));
                                    } else {
                                        setCheckedItems([]);
                                    }
                                }}/>Select All
                            </label>
                        </th>
                        <th>Proxy</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {proxies.map((proxy) => (
                        <ProxyRow
                        key={proxy.id}
                        rowKey={proxy.id}
                        proxyItem={proxy}
                        isChecked={checkedItems.includes(proxy.id)}
                        handleCheckboxChange={(id) => {
                            setCheckedItems((prev) => {
                                console.log(prev);

                                return prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]
                            });

                            if (checkedItems.length === proxies.length - 1) {
                                setIsCheckedAll(true);
                            } else {
                                setIsCheckedAll(false);
                            }
                        }}
                        handleDeleteProxy={deleteProxy}
                        handleEditProxy={editProxy}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ProxyList;
