import React, { useState } from "react";
import "../styles/dropdown.css"
import { Prefix } from "../Interfaces/InterfacePrefix";
import { useNavigate, useParams } from "react-router-dom";
import { sendPost } from "../utils/helper";

interface DropdownProps {
  options: Prefix[];
  onAddOption: (newOption: string) => void;
  onDeleteOption?: (removeOption?: string) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ options, onAddOption, onDeleteOption = () => {} }) => {
  const { alias_name } = useParams<{ alias_name: string }>();
  const [selectedOption, setSelectedOption] = useState<string>(alias_name || "choose");
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    if (value === "add") {
      const newOption = prompt("Enter the new prefix:");
      if (newOption && !options.some(option => option.prefix_name === newOption)) {
        onAddOption(newOption);
      }
    } else if (value === "choose") {
      setSelectedOption(value);
      return;
    } else {
      setSelectedOption(value);
      navigate(`/admin/prefix/${value}`)
    }
  };

  const handleDelete = async () => {
    const res = await sendPost("api/group-prefix/delete", {
      "alias_name": alias_name,
    }, {
      headers: {
        Accept: "application/json",
      }
    });
    if (!res.data.id_delete) {
      alert("Deleting Prefix has wrong !!")
      return;
    }
    onDeleteOption(selectedOption)
  }

  return (
    <div className="dropdown-content">
      <div className="dropdown-container">
        <label htmlFor="dropdown">Select a Prefix</label>
        <select
          id="dropdown"
          className="dropdown-select"
          defaultValue={alias_name}
          onChange={handleChange}
        >
          <option value="choose">Choose Prefix</option>
          {options.map((option, index) => (
            <option key={option.id} value={option.alias_name} selected={alias_name === option.alias_name}>
              {option.prefix_name}
            </option>
          ))}
          <option value="add">Add Prefix</option>
        </select>
      </div>
      {selectedOption !== "add" && selectedOption !== "choose" && (
        <button className="btn-delete-prefix" onClick={handleDelete}>Delete Prefix</button>
      )}
    </div>

  );
};

export default Dropdown;
