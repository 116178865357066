import React from "react";
import FromLogin from "../components/FromLogin"

const Login: React.FC = () => {
  return (
    <div className="content-contaier">
      <FromLogin/>
    </div>
  );
};

export default Login;
