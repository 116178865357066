import React from 'react';
import "../styles/resultTable.css"
import { ResultTableProps } from '../Interfaces';

const ResultTable: React.FC<ResultTableProps> = ({ proxies, checkUrls, isShow }) => {
  return (
    <div id="table-container" className="table-container" style={{ display: isShow ? 'flex' : 'none' }}>
      <table id="result-table" className="result-table" width="100%">
        <thead>
          <tr id="thead-proxy">
            <th className="background">
              <div>
                <span className="bottom">domain</span>
                <span className="top">proxy</span>
              </div>
            </th>
            {proxies.map(proxy => (
              <th key={proxy.ipv4}>{proxy.ipv4}<br/>{`(${proxy.desc})` || ''}</th>
            ))}
          </tr>
        </thead>
        <tbody id="tbody-domain">
          {checkUrls.map((domain, index) => (
            <tr key={index}>
              <td>{domain.url}</td>
              {domain.result.map((text: any, i: any) => (
                <td key={i}>{text}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ResultTable;
