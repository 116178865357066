import axios, { AxiosRequestConfig } from "axios";

function setCookie(name: string, value: string, days: number): void {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
}

function getCookie(name: string): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift() || null;
  return null;
}

export const sendGet = async (path: string, options: AxiosRequestConfig = {}) => {
  return await axios.get(`${process.env.REACT_APP_HOST_URL}/${path}`, options);
}

export const sendPost = async (path: string, body = {}, options: AxiosRequestConfig = {}) => {
  return await axios.post(`${process.env.REACT_APP_HOST_URL}/${path}`, body, options);
}

async function checkLogin(navigate: any , location: any): Promise<void> {
  try {
    const response = await sendPost("api/users/me", {}, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getCookie('access_token')}`,
      }
    });

    const data = response.data;

    if (data.is_expired && location.pathname === "/admin/login") {
      navigate("/admin");
    }
  } catch (error: any) {
    if (location.pathname !== "/admin/login") {
      navigate("/admin/login");
    }
  }
}

export {
  getCookie,
  setCookie,
  checkLogin,
 };
