import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../Pages/login";
import Proxies from "../Pages/proxies";
import CheckLink from "../Pages/checkLink";
import PrefixPage from "../Pages/prefixPage";
import "../App.css";

const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin/login" element={<Login />}/>
        <Route path="/admin" element={<Proxies />} />
        <Route path="/admin/check-link" element={<CheckLink />} />
        <Route path="/admin/prefix/:alias_name" element={<PrefixPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
