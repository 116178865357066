import React, { useState } from 'react';
import Tabs from '../components/Tabs';
import CheckLinkForm from '../components/CheckLinkForm';
import ResultTable from '../components/ResultTable';
import "../styles/checkLink.css"

const CheckLink: React.FC = () => {
    const [proxies, setProxies] = useState<any[]>([]);
    const [checkUrls, setCheckUrls] = useState<any[]>([]);
    const [is_show, setIsShow] = useState<boolean>(false);

    const handleResultReceived = (newProxies: any[], newCheckUrls: any[], is_show: boolean) => {
        setProxies(newProxies);
        setCheckUrls(newCheckUrls);
        setIsShow(is_show);
    };

    return (
        <div className="container">
            <Tabs />
            <CheckLinkForm onResultReceived={handleResultReceived} />
            <ResultTable proxies={proxies} checkUrls={checkUrls} isShow={is_show}/>
        </div>
    );
};

export default CheckLink;
